import React from 'react'
// import cx from "classnames"

import SEO from '../utils/seo'

import Default from './../layouts/Default/Default'
import Section from './../layouts/Section/Section'
import Container from './../layouts/Container/Container'
import Text from '../components/Text/Text'

const notes = ({ children, className, ...restProps }) => {
  return (
    <Default light>
      <SEO
        title="Notes"
        description="Here're few topics that I'm planning on to write in the future. I'll
          just dump it right here for my personal reminder."
      />
      <Section>
        <Container as="header" narrow style={{ marginBottom: 48 }}>
          <Text as="h1" display3 style={{ marginBottom: 24 }}>
            Notes
          </Text>
          <Text as="p">
            Here're few topics that I'm planning on to write in the future.{' '}
            <br />
            I'll just dump it right here for my personal reminder.
          </Text>
        </Container>
        <Container as="ul" post style={{ listStyle: 'none' }}>
          <Text as="li" large style={{ marginBottom: 16 }}>
            Getting Started with React for Designers
          </Text>
          <Text as="li" large style={{ marginBottom: 16 }}>
            Simple SEO for your website
          </Text>
          <Text as="li" large style={{ marginBottom: 16 }}>
            Building your own blog with React, Gatsby, and Netlify
          </Text>
        </Container>
      </Section>
    </Default>
  )
}

export default notes
